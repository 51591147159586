import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "gatsby-plugin-react-i18next";

const ZendeskChat = ({ id, key }) => {
  return (
    <Helmet>
      <script id={id} src={`https://static.zdassets.com/ekr/snippet.js?key=${key}`}></script>
    </Helmet>
  );
};

ZendeskChat.propTypes = {
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
};

ZendeskChat.defaultProps = {
  id: "ze-snippet",
  key: "22bcf803-ee8d-45ee-abcc-733b96740707", //TODO use .env
};

export default ZendeskChat;

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Img from "gatsby-image";

const ProductImages = ({ nodes, productName }) => {
  const { t } = useTranslation();

  return (
    <>
      {nodes.map((file, index) => {
        return (
          <div className="item-gallery border m-1 m-md-2" key={`pdf-${index}`}>
            <a data-target="#modalIMG" data-toggle="modal" className="d-block h-100" href={`${file.node.image.zoom.src}`}>
              <Img fixed={file.node.image.thumb} alt={file.node.name} title={t(`Capture d'écran du module ${productName}`)} className="img-fluid" />
            </a>
          </div>
        );
      })}
    </>
  );
};

ProductImages.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  productName: PropTypes.string.isRequired,
};

ProductImages.defaultProps = {};

export default ProductImages;

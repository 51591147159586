import React, { useEffect, useState } from "react";
import compareVersions from "compare-versions";
import $ from "jquery";
import ImgLogoPrestashop from "../../img/logos/logo-prestashop2.svg";
import ImgLogoPhp from "../../img/logos/logo-php.svg";
import CheckoutForm from "../../components/CheckoutForm";
import { graphql } from "gatsby";
import ProductImages from "../../components/ProductImages";
import BannerNeedExpert from "../../components/BannerNeedExpert";
import ProductMainImage from "../../components/ProductMainImage";
import Seo from "../../components/Seo";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import ZendeskChat from "../../components/ZendeskChat";
import Img from "gatsby-image";
import LogoPrestashop from "../../components/LogoPrestashop";
import BannerQuotePrestashop from "../../components/BannerQuotePrestashop";

const PrestashopModule = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const page = data.markdownRemark;
  /*
  const productImageLogo = data.allFile.edges.find(
    (edge) => edge.node.name === "logo"
  ).node;
  */
  const productImageLogo = data.productLogo.image;
  const productImageIntro = data.productMainImage.image;
  const cartConfig = {
    // default choices
    //price_id: page.frontmatter.product.price.stripe.ps17,
    price_id: "",
    quantity: 1,
  };

  const [formLoaderState, setFormLoaderState] = useState(false);
  const [formErrorMessages, setFormErrorMessages] = useState([]);
  const [cartPsVersion, setCartPsVersion] = useState(cartConfig.price_id);
  // eslint-disable-next-line no-unused-vars
  const [cartQty, setCartQty] = useState(cartConfig.quantity);
  const [modalProductImageZoomSrc, setModalProductImageZoomSrc] = useState("");
  const [modalProductImageZoomAlt, setModalProductImageZoomAlt] = useState("");
  const [modalProductImageZoomTitle, setModalProductImageZoomTitle] = useState("");
  const [productFullIntroVisible, setProductFullIntroVisible] = useState(false);

  // form (add to cart)
  const changePrestashopVersion = (e) => {
    cartConfig.price_id = 0;
    setFormErrorMessages([]);
    setCartPsVersion(e.target.value);
  };

  const convertMinimalVersionToRange = (version) => version.replace(/(\.0)+$/g, ``);

  useEffect(() => {
    // img modal
    $("#modalIMG").on("show.bs.modal", function (e) {
      var $link = $(e.relatedTarget);

      setModalProductImageZoomSrc($link.prop("href"));
      setModalProductImageZoomAlt($link.prop("alt"));
      setModalProductImageZoomTitle($link.prop("title"));
    });

    return () => {
      console.log("Remove previous registered events listeners"); // @todo Remove previous registered events listeners
    };
  }, []);

  const seoSchemaJson = JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "SoftwareApplication",
    name: page.frontmatter.page_title,
    description: page.frontmatter.seo.schema.description,
    keywords: page.frontmatter.seo.schema.keywords,
    featureList: page.frontmatter.seo.schema.feature_list,
    fileSize: page.frontmatter.seo.schema.file_size,
    acquireLicensePage: `${data.site.siteMetadata.siteUrl}${page.fields.slug}`,
    operatingSystem: page.frontmatter.seo.schema.operating_system,
    applicationCategory: page.frontmatter.seo.schema.category,
    applicationSubCategory: page.frontmatter.seo.schema.sub_category,
    isBasedOn: "https://www.prestashop.com",
    genre: page.frontmatter.seo.schema.genre,
    // TODO add all screenshots?
    screenshot: `${data.site.siteMetadata.siteUrl}${productImageIntro.large.src}`,
    image: `${data.site.siteMetadata.siteUrl}${productImageLogo.large.src}`,
    video: "",
    softwareVersion: page.frontmatter.product.latest_version.version,
    downloadUrl: `${data.site.siteMetadata.siteUrl}${page.fields.slug}`,
    softwareRequirements: page.frontmatter.seo.schema.software_requirements,
    permissions: page.frontmatter.seo.schema.permissions,
    isAccessibleForFree: false,
    audience: {
      "@type": "Audience",
      audienceType: page.frontmatter.seo.schema.audience_type,
    },
    maintainer: {
      "@type": "Corporation",
      name: "ComExpertise",
      url: data.site.siteMetadata.siteGeneralUrl,
      contactPoint: [
        {
          "@type": "ContactPoint",
          telephone: data.site.siteMetadata.phone,
          email: data.site.siteMetadata.emailGeneral,
          contactType: "Commercial",
          contactOption: "TollFree",
        },
        {
          "@type": "ContactPoint",
          telephone: data.site.siteMetadata.phoneSupport,
          email: data.site.siteMetadata.emailSupport,
          contactType: "Support technique",
          contactOption: "TollFree",
        },
      ],
    },
    author: `David DIVERRES <david@comexpertise.com>, ComExpertise <${data.site.siteMetadata.emailGeneral}>"`,
    copyrightHolder: `ComExpertise <${data.site.siteMetadata.emailGeneral}>"`,
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: page.frontmatter.product.customers_rating.aggregate_rating,
      ratingCount: page.frontmatter.product.customers_rating.count,
    },
    // TODO make languages dynamics
    inLanguage: [
      {
        "@type": "Language",
        name: "Français",
      },
      {
        "@type": "Language",
        name: "Anglais",
      },
    ],
    offers: {
      "@type": "Offer",
      acceptedPaymentMethod: ["http://purl.org/goodrelations/v1#PaymentMethodCreditCard"],
      availableDeliveryMethod: ["http://purl.org/goodrelations/v1#DeliveryModeDirectDownload"],
      category: page.frontmatter.seo.schema.categories_path,
      deliveryLeadTime: {
        "@type": "QuantitativeValue",
        value: "Instantly",
      },
      sku: page.frontmatter.product.sku,
      url: `${data.site.siteMetadata.siteUrl}${page.fields.slug}`,
      itemCondition: "https://schema.org/NewCondition",
      priceValidUntil: "2099-01-01",
      availability: "https://schema.org/OnlineOnly",
      priceSpecification: {
        "@type": "PriceSpecification",
        price: page.frontmatter.product.price,
        priceCurrency: "EUR",
        valueAddedTaxIncluded: true,
        description: "Prix HT pour 1 (une) boutique/licence",
      },
      eligibleCustomerType: page.frontmatter.seo.schema.eligible_customer_type,
      itemOffered: {
        "@type": "DigitalDocument",
        hasDigitalDocumentPermission: {
          "@type": "DigitalDocumentPermission",
          permissionType: "https://schema.org/ReadPermission",
        },
        version: page.frontmatter.product.latest_version.version,
      },
    },
  });

  const isModuleCompatibleWithPrestashopVersion = (version) => {
    return page.frontmatter.product.versions.some((objVersion) => compareVersions.compare(version, objVersion.compatibility.prestashop.min, ">=") && compareVersions.compare(version, objVersion.compatibility.prestashop.max, "<="));
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/control-has-associated-label */}

      <Seo title={page.frontmatter.seo.title} description={page.frontmatter.seo.description}>
        <script type="application/ld+json">{`${seoSchemaJson}`}</script>
        <meta name="keywords" content={page.frontmatter.seo.schema.keywords} />
        <body className="page-product" />
      </Seo>

      <ZendeskChat />

      <div className="container">
        <div className="card">
          <div className="row no-gutters">
            <aside className="col-12 col-xl-6 border-right d-flex flex-column align-items-center justify-content-between comx-content-left">
              <article className="gallery-wrap w-100">
                <div className="img-big-wrap m-2 m-md-3 m-lg-4 m-xl-5">
                  <div>
                    <ProductMainImage image={{ name: data.productMainImage.name, ...data.productMainImage.image }} />
                  </div>
                </div>
                <div className="img-small-wrap mt-3">
                  <ProductImages nodes={data.productImages.edges} productName={page.frontmatter.product.name} />
                </div>
              </article>

              <article className="my-4 mb-lg-5 d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-center product-badges text-center">
                {isModuleCompatibleWithPrestashopVersion("1.7") && <Img fixed={data.prestashop17Badge.image.fixed} className="my-3 mx-4" alt={t("Logo PrestaShop 1.7")} title={t("Module compatible avec PrestaShop 1.7 !")} />}

                <Img fixed={data.prestashopPartner.image.fixed} className="my-3 mx-4" alt={t("Badge officiel des agences web partenaires de PrestaShop")} title={t("Partenaire PrestaShop")} />
              </article>
            </aside>

            <aside className="col-12 col-xl-6">
              <article className="card-body p-4 p-lg-5" id="product">
                <header>
                  <h1 className="title mb-3 h3">
                    {page.frontmatter.page_title}
                    <span className="ml-1 badge badge-success rounded">{page.frontmatter.product.latest_version.tag}</span>
                  </h1>
                </header>

                <section className="price-detail-wrap mb-4">
                  <span className="price h3 text-success">
                    <span className="num">{page.frontmatter.product.price}</span> <span className="currency">€</span>
                  </span>
                  <span>
                    <Trans> / licence</Trans>
                  </span>
                  <span className="text-muted d-block small">
                    <Trans>Prix HT pour 1 boutique</Trans>
                  </span>
                </section>

                <section id="product-intro">
                  <h2 className="h6 text-muted sr-only">
                    <Trans>Présentation du module</Trans>
                  </h2>
                  <div className={`desc ${productFullIntroVisible ? "comx-visible" : ""}`} dangerouslySetInnerHTML={{ __html: page.frontmatter.product.desc.intro }} />

                  {productFullIntroVisible ? (
                    ""
                  ) : (
                    <p className="read-more small animate__animated animate__fadeInDown">
                      <Link
                        to="#product-intro-more"
                        className="text-muted"
                        onClick={(event) => {
                          event.preventDefault();
                          setProductFullIntroVisible(!productFullIntroVisible);
                        }}
                      >
                        <Trans>lire la suite...</Trans>
                      </Link>
                    </p>
                  )}
                </section>

                {productFullIntroVisible && (
                  <div>
                    <div className="animate__animated animate__fadeIn animate__slow">
                      <section id="product-intro-more">
                        <h4 className="h6 text-muted">
                          <Trans>Fonctionnement du module</Trans>
                        </h4>
                        <div dangerouslySetInnerHTML={{ __html: page.frontmatter.product.desc.howtowork }} />
                      </section>

                      <section id="product-text" dangerouslySetInnerHTML={{ __html: page.html }} />

                      <p className="read-less small animate__animated animate__fadeInDown">
                        <Link
                          to="#product"
                          className="text-muted"
                          onClick={(event) => {
                            event.preventDefault();
                            setProductFullIntroVisible(!productFullIntroVisible);
                          }}
                        >
                          <Trans>masquer la description...</Trans>
                        </Link>
                      </p>
                    </div>
                  </div>
                )}

                {page.frontmatter.product.desc.footer && page.frontmatter.product.desc.footer.text && (
                  <>
                    <p className={page.frontmatter.product.desc.footer.container_class}>
                      <i className="bi bi-check2-square"></i> <span dangerouslySetInnerHTML={{ __html: page.frontmatter.product.desc.footer.text }} />
                    </p>
                  </>
                )}

                <div className="comx-add-to-cart shadow-sm ml-n4 ml-lg-n5 mr-n4 mr-lg-n5 px-4 px-lg-5 py-4 my-5">
                  {formErrorMessages.length > 0 && (
                    <div id="payment-error-message" className="animate__animated animate__flipInX alert alert-danger mt-3">
                      {formErrorMessages.map((message, i) => (
                        <p key={i} className="mb-0">
                          <i className="bi bi-exclamation-diamond-fill"></i> {message}
                        </p>
                      ))}
                    </div>
                  )}

                  {formLoaderState ? (
                    <div className="d-flex animate__animated animate__fadeInUp" id="comx-form-loader">
                      <div className="mr-4 text-success">Patientez...</div>
                      <div className="w-100">
                        <div className="progress mb-4">
                          <div className="bg-success progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{ width: "40%" }}></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {/*
                        <dl className="param param-inline">
                            <dt><label for="addtocart_quantity">Quantité :</label></dt>
                            <dd>
                                <select className="form-control form-control-sm"
                                    name="addtocart_quantity" id="addtocart_quantity">
                                    <option value="1" selected>1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                </select>
                            </dd>
                        </dl>
                    */}

                      <LogoPrestashop useLink={false} className="mb-1" height={24} />
                      <div className={`form-inline mb-3 ${cartPsVersion && "was-validated"}`}>
                        <label className={`mr-2 ${cartPsVersion ? "text-success" : "text-danger font-weight-bold"}`} htmlFor="addtocart_ps_version">
                          {!cartPsVersion && <i className="mr-2 bi bi-exclamation-diamond-fill"></i>} <Trans>Choisissez votre version de PrestaShop :</Trans>
                        </label>

                        <select className="form-control form-control-sm is-invalid" name="addtocart_ps_version" id="addtocart_ps_version" onChange={changePrestashopVersion} defaultValue="">
                          <option value="">-</option>
                          {page.frontmatter.product.versions.map((objVersion, iObjVersion) => (
                            <option value={objVersion.stripe_price} key={`ps-version-${iObjVersion}`}>
                              {convertMinimalVersionToRange(objVersion.compatibility.prestashop.min)} (max. {objVersion.compatibility.prestashop.max})
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="comx-actions">
                        <CheckoutForm quantity={cartQty} stripePriceId={cartPsVersion} formLoaderState={formLoaderState} setFormLoaderState={setFormLoaderState} setFormErrorMessages={setFormErrorMessages} formErrorMessages={formErrorMessages}>
                          <a href={`mailto:${data.site.siteMetadata.emailGeneral}`} className="btn btn-lg btn-outline-primary text-uppercase px-4 px-lg-5 mt-3 mt-sm-0 flex-grow-1 flex-sm-grow-0">
                            <i className="bi bi-chat-left-quote mr-1"></i>{" "}
                            <span className="d-md-none">
                              <Trans>
                                Poser
                                <br />
                                une question
                              </Trans>
                            </span>
                            <span className="d-none d-md-inline-block">
                              <Trans>Une question ?</Trans>
                            </span>
                          </a>
                        </CheckoutForm>
                      </div>
                    </div>
                  )}

                  <p className="mt-2 mb-4 text-left text-success small">
                    <i className="bi bi-lock"></i> <Trans>paiement sécurisé (stripe.com)</Trans>
                  </p>

                  <p className="mb-0 alert alert-success">
                    <i className="bi bi-shield-fill-check"></i>{" "}
                    <Trans>
                      Le tarif indiqué inclus l'assistance technique &amp; les mises à jour <b>pendant 90 jours</b>.
                    </Trans>
                  </p>
                </div>

                {/*<hr className="mx-auto w-50 my-5" />*/}

                <dl className="text-muted list-inline">
                  <dt className="list-inline-item">
                    <Trans>Dernière version du module :</Trans>
                  </dt>
                  <dd className="list-inline-item">{page.frontmatter.product.latest_version.full_date}</dd>
                </dl>

                {page.frontmatter.seo.schema.audience_type && (
                  <>
                    <h3 className="h6 text-cendter text-muted">
                      <Trans>A qui s'adresse ce module ?</Trans>
                    </h3>
                    <p>
                      <span dangerouslySetInnerHTML={{ __html: page.frontmatter.seo.schema.audience_type }} />
                    </p>
                  </>
                )}

                <h2 className="h6 text-muted">
                  <Trans>Exigences techniques</Trans>
                </h2>
                <p>
                  <Trans>
                    Vérifiez la <b>compatibilité de votre version de PrestaShop</b> et de votre <b>environnement technique de production</b> <i>(OS, PHP, MySql, Apache/Nginx, ...)</i> afin de répondre aux exigences techniques requises pour installer et utiliser le module <em>{page.frontmatter.product.name}</em>.
                  </Trans>
                </p>

                <h3 className="h6 text-muted">
                  <Trans>Testé &amp; certifié, 100% compatible</Trans>
                </h3>
                <p>
                  <Trans>
                    <b>Ce module a été testé et vérifié</b> sur toutes les versions de PrestaShop suivantes, et dans les conditions techniques définies dans le tableau de compatibilité ci-dessous.
                  </Trans>
                </p>
                <p className="text-warning lead">
                  <i className="bi bi-exclamation-triangle"></i> <Trans>Assurez-vous de télécharger la version compatible avec votre version de PrestaShop :</Trans>
                </p>

                <table className="table table-bordered table-sm mb-0 comx-compatibility-table">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">
                        <Trans>Module version</Trans>
                      </th>
                      <th scope="col" style={{ width: "40%" }} className="text-center">
                        <a href="https://www.prestashop.com" target="_blank" rel="noreferrer">
                          <span className="sr-only">
                            <Trans>Compatibilité avec PrestaShop</Trans>
                          </span>
                          <img src={ImgLogoPrestashop} alt={t("PrestaShop")} className="comx-grayscale" title={t("Logo de PrestaShop")} height="16" />
                        </a>
                      </th>
                      <th scope="col" style={{ width: "40%" }} className="text-center">
                        <a href="https://www.php.net" target="_blank" rel="noreferrer">
                          <span className="sr-only">
                            <Trans>Compatibilité avec PHP</Trans>
                          </span>
                          <img src={ImgLogoPhp} alt={t("Logo de PHP")} className="comx-grayscale" title={t("PHP")} height="16" />
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {page.frontmatter.product.versions.map((objVersion, iObjVersion) => (
                      <tr key={`compatibility-row-${iObjVersion}`}>
                        <th scope="row">
                          <span className="badge badge-success rounded comx-fs-inherit comx-text-lowercase">{`v${objVersion.version}`}</span>
                        </th>
                        <td>
                          <table className="table table-borderless table-sm mb-0">
                            <tbody>
                              <tr>
                                <th className="text-muted text-nowrap">min. :</th>
                                <td className="mb-0">{objVersion.compatibility.prestashop.min}</td>
                              </tr>
                              <tr>
                                <th className="text-muted text-nowrap">max. :</th>
                                <td className="mb-0">{objVersion.compatibility.prestashop.max}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>{`${objVersion.compatibility.php.min}+`}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </article>
            </aside>
          </div>
        </div>

        <div className="my-5">
          <p className="text-center mt-0 mb-2 text-muted">
            <Trans>Une question avant achat ?</Trans>{" "}
            <a href={`mailto:${data.site.siteMetadata.emailGeneral}`} className="text-primary" target="_blank" rel="noreferrer">
              <Trans>Contactez-nous</Trans>
            </a>{" "}
            <i className="bi bi-chat-left-dots-fill text-primary"></i>
          </p>

          <hr className="mt-2 mb-0 w-25 mx-auto" />
        </div>
      </div>

      <div className="pt-4">
        <BannerNeedExpert containerClasses="mt-5" software="PrestaShop" />
      </div>

      <BannerQuotePrestashop email={data.site.siteMetadata.emailGeneral} title={t("Une idée pour votre boutique ?")} />

      {/* img modal */}
      <div aria-hidden="true" aria-labelledby="myModalLabel" className="modal fade" id="modalIMG" role="dialog" tabIndex="-1">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body mb-0 p-0">
              <img className="img-fluid comx-dynamic-src w-100" src={modalProductImageZoomSrc} alt={modalProductImageZoomAlt} title={modalProductImageZoomTitle} />
            </div>
            <div className="modal-footer justify-content-end">
              <div>
                <Link className="btn btn-link text-muted comx-dynamic-href" to={modalProductImageZoomSrc} title={modalProductImageZoomTitle} target="_blank" rel="noreferrer">
                  <i className="bi bi-card-image"></i> Image seule
                </Link>
              </div>
              <button className="btn btn-outline-primary text-center" data-dismiss="modal" type="button">
                <i className="bi bi-x"></i> <Trans>Fermer</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* / img modal */}
    </>
  );
};

export const query = graphql`
  query ($slug: String!, $language: String!, $product_id: String) {
    prestashop17Badge: file(relativePath: { eq: "prestashop-1.7-badge.png" }) {
      image: childImageSharp {
        fixed(height: 150, grayscale: true) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    prestashopPartner: file(relativePath: { eq: "prestashop-partner.png" }) {
      image: childImageSharp {
        fixed(height: 150, grayscale: true) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    productLogo: file(sourceInstanceName: { eq: "img_products" }, relativeDirectory: { eq: $product_id }, name: { eq: "logo" }) {
      name
      image: childImageSharp {
        large: fixed(width: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    productMainImage: file(sourceInstanceName: { eq: "img_products" }, relativeDirectory: { eq: $product_id }, name: { eq: "intro" }) {
      name
      image: childImageSharp {
        thumb: fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
        zoom: fluid(maxWidth: 2600) {
          ...GatsbyImageSharpFluid
        }
        large: fixed(width: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    productImages: allFile(filter: { sourceInstanceName: { eq: "img_products" }, relativeDirectory: { eq: $product_id } }) {
      edges {
        node {
          name
          image: childImageSharp {
            thumb: fixed(width: 180) {
              ...GatsbyImageSharpFixed
            }
            zoom: fluid(maxWidth: 2600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        page_title
        seo {
          title
          description
          schema {
            categories_path
            category
            sub_category
            feature_list
            file_size
            operating_system
            genre
            description
            audience_type
            eligible_customer_type
            keywords
            software_requirements
            permissions
          }
        }
        screenshots {
          title
        }
        product {
          id
          sku
          name
          alt_name
          price
          desc {
            intro
            howtowork
            footer {
              text
              container_class
            }
          }
          latest_version {
            version
            tag
            full_date
          }
          versions {
            version
            stripe_price
            compatibility {
              php {
                min
                max
              }
              prestashop {
                min
                max
              }
            }
          }
          customers_rating {
            aggregate_rating
            count
          }
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
        siteGeneralUrl
        emailSupport
        emailGeneral
        phone
        phoneSupport
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PrestashopModule;

import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";

const BannerNeedExpert = (props) => {
  const { software, containerClasses } = props;

  return (
    <section className={`bg-dark text-white card ${containerClasses || "my-5"}`} id="call-software-expert">
      <div className="card-body text-center">
        <h2 className="display-4 mt-4 text-white">
          <Trans>
            Besoin d'aide avec <strong>{software}</strong> ?
          </Trans>
        </h2>
        <p>
          <i>
            <Trans>Assistance technique, développement de fonctionnalités personnalisées, optimisation de votre boutique, audits &amp; conseils techniques...</Trans>
          </i>
        </p>
        <p className="h4 text-white mt-5 mb-3 text-success">
          <Trans>..parlez-en avec un expert {software}, une première réponse sous 3H !</Trans>
          <sup className="text-muted">*</sup>
        </p>

        <p>
          <a className="btn btn-outline-success btn-lg" href={`mailto:${process.env.GATSBY_EMAIL_SUPPORT}`}>
            <i className="bi bi-life-preserver"></i> <Trans>Demander de l'aide</Trans>
          </a>
        </p>

        <p className="text-center text-md-right mt-5 mb-0 text-muted comx-lh-1">
          <small>
            <Trans>*délai de réponse moyen constaté sur les 3 dernières années (2018 - 2020)</Trans>
          </small>
        </p>
      </div>
    </section>
  );
};

export default BannerNeedExpert;

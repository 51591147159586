// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://stripe.com/docs/payments/accept-a-payment#web

import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const Component = ({ children, quantity, stripePriceId, formLoaderState, setFormLoaderState, setFormErrorMessages }) => {
  const { t } = useTranslation();
  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (formLoaderState) {
      return;
    }

    setFormErrorMessages([]);
    setFormLoaderState(true);

    // Form validation
    if (!stripePriceId) {
      setFormErrorMessages([t("Choisissez votre version minimale de PrestaShop pour télécharger la version appropriée du module et de sa licence.")]);
      setFormLoaderState(false);

      return false;
    }

    // Get Stripe.js instance
    const stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    const response = await fetch(`${process.env.GATSBY_API_URL}/buy/checkout-session`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        quantity: quantity,
        stripePriceId: stripePriceId,
      }),
      mode: "cors",
      cache: "default",
    })
      .then(async (response) => {
        return new Promise(function (resolve) {
          setTimeout(() => {
            if (!(response && response.ok)) {
              //throw new Error(`${response.statusText} (${response.status})`);
              console.error("Payment API request received a bad response:", response.status, response.statusText);
              resolve(false);
            }

            resolve(response);
          }, 1000);
        });
      })
      .catch((error) => {
        console.error("Unable to create a new checkout session, payment API request failed:", error.message);
      });

    if (!response) {
      setFormErrorMessages([t("Désolé, le paiement n'est pas disponible pour le moment :(")]);
      setFormLoaderState(false);

      return false;
    }

    const { sessionId } = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: sessionId,
    });

    if (result && result.error) {
      console.error("Payment request failed!", result.error.message);
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.

      // Handle any errors returns from Checkout
      console.error("Unable to redirect to the checkout page:", result.error.message);
      setFormLoaderState(false);
      setFormErrorMessages([t("Désolé, la redirection vers la page de paiement n'a pas fonctionné :"), result.error.message]);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="d-flex flex-column flex-sm-row">
      <button className="btn btn-lg btn-success text-white btn-outline-success text-uppercase shadow px-4 px-lg-5 mr-sm-2 flex-grow-1" id="checkout-button" role="link" type="submit">
        <i className="bi bi-cloud-download mr-1"></i> <Trans>Télécharger</Trans>
      </button>

      {children}
    </form>
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

const CheckoutForm = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <Component children={props.children} quantity={props.quantity} stripePriceId={props.stripePriceId} setFormLoaderState={props.setFormLoaderState} formErrorMessages={props.formErrorMessages} setFormErrorMessages={props.setFormErrorMessages} />
    </Elements>
  );
};

CheckoutForm.propTypes = {
  children: PropTypes.node.isRequired,
  quantity: PropTypes.number.isRequired,
  stripePriceId: PropTypes.string.isRequired,
  formLoaderState: PropTypes.bool.isRequired,
  setFormLoaderState: PropTypes.func.isRequired,
  formErrorMessages: PropTypes.array.isRequired,
  setFormErrorMessages: PropTypes.func.isRequired,
};

CheckoutForm.defaultProps = {
  quantity: 1,
};

export default CheckoutForm;

import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

const ProductMainImage = ({ image }) => {
  return image ? (
    <a data-target="#modalIMG" data-toggle="modal" className="d-block" href={image.zoom.src}>
      <Img fluid={image.thumb} alt={image.name} className="d-block" />
    </a>
  ) : (
    <></>
  );
};

ProductMainImage.propTypes = {
  image: PropTypes.object.isRequired,
};

ProductMainImage.defaultProps = {};

export default ProductMainImage;
